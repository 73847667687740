import {
  FETCH_INSCRIPTIONS_SUCCESS,
  FETCH_MATCHES,
  FETCH_INSCRIPTIONS_FAILURE,
  FETCH_MY_INSCRIPTIONS_REQUEST,
  REGISTER_TO_MATCH,
  CANCEL_REGISTER_TO_MATCH,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER
} from "constants/ActionTypes";


export const getMatches = () => {
  return {
    type: FETCH_MATCHES,
  }
}

export const fetchInscriptionsRequest = () => {
  return {
    type: FETCH_MY_INSCRIPTIONS_REQUEST,
  }
}

export const fetchInscriptionsSuccess = (inscriptions) => {
  return ({
    type: FETCH_INSCRIPTIONS_SUCCESS,
    payload: inscriptions
})};

export const fetchInscriptionsFailure = (error) => ({
  type: FETCH_INSCRIPTIONS_FAILURE,
  payload: error
});

export const registerToMatch = (matchId, paymentCode) => {
  return {
    type: REGISTER_TO_MATCH,
    payload: { match: matchId, paymentCode: paymentCode}
  }
}

export const cancelRegister = (inscriptionId) => {
  return {
    type: CANCEL_REGISTER_TO_MATCH,
    payload: { inscriptionId }
  }
}

export const showLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
