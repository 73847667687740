import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {
  auth,
  googleAuthProvider,
} from "../../firebase/firebase";
import {
  SIGNIN_GOOGLE_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER
} from "constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess} from "../../appRedux/actions/Auth";
import {
  userGoogleSignInSuccess,
} from "../actions/Auth";

import baseUrl from "./config";

const createUserWithEmailPasswordRequest = async (email, password) =>
  await  auth.createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await  auth.signInWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signOutRequest = async () =>
  await  auth.signOut()
    .then(authUser => authUser)
    .catch(error => error);


const signInUserWithGoogleRequest = async () =>
  await  auth.signInWithPopup(googleAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const validateUserInBackend = async (firebaseUser) => {
  try {
    const response = await axios.get(`${baseUrl}/items/user?fields[]=*.*&filter[user_external_id][_eq]=${firebaseUser.uid}`);
    const { data } = response.data;
    if (data && data.length > 0) {
      localStorage.setItem('id', data[0].id);
      return data[0];
    } else {
      // if the user doesn`t exist, we need to create it
      return await createUserInBackend(firebaseUser)
    }
  } catch (error) {
    console.error('Error in validateUserInBackend:', error);
    return null;
  }
}

const createUserInBackend = async (firebaseUser) => {
  try {
    const fullName = firebaseUser.displayName && firebaseUser.displayName.length > 0 ? firebaseUser.displayName : firebaseUser.email.split('@')[0];
    // http://localhost:5005
    const response = await axios.post(`https://admin.goldenleague.es/items/user`, {
      "full_name": fullName,
      "email": firebaseUser.email,
      "user_external_id": firebaseUser.uid
    })
    const { data } = response.data;
    if(data) {
      localStorage.setItem('id', data.id);
      return data;
    } else {
      throw new Error(JSON.stringify(response))
    }
  } catch (error) {
    console.error('Error in User backend creation: ', error.message);
  }
}

/**
 * Create user/pass in firebase
 * @param {*} param0
 */
function* createUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      localStorage.setItem('user_email', signUpUser.user.email);
      const validation = yield call(validateUserInBackend, signUpUser.user);
      if (validation) {
        yield put(userSignUpSuccess(signUpUser.user.uid));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      console.log('After login: ', signUpUser);
      localStorage.setItem('user_id', signUpUser.user.uid);
      localStorage.setItem('user_email', signUpUser.user.email);
      const validation = yield call(validateUserInBackend, signUpUser.user);
      if (validation) {
        yield put(userGoogleSignInSuccess(signUpUser.user.uid));
      } else {
        yield put(showAuthMessage("User validation failed in backend"));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* signInUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      localStorage.setItem('user_id', signInUser.user.uid);
      localStorage.setItem('user_email', signInUser.user.email);
      const validation = yield call(validateUserInBackend, signInUser.user);
      if (validation) {
        yield put(userSignInSuccess(signInUser.user.uid));
      } else {
        yield put(showAuthMessage("User validation failed"));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem('user_id');
      localStorage.removeItem('user_email');
      localStorage.removeItem('id');
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signOutUser)]);
}
