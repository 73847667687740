import React, {useEffect} from "react";
import {Button, Form, Input} from "antd";
import {Link, useHistory} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  userGoogleSignIn,
  userSignUp,
} from "appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import {message} from "antd/lib/index";
import CircularProgress from "components/CircularProgress/index";
import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";

const FormItem = Form.Item;

const SignUp = (props) => {


  const dispatch = useDispatch();
  const history = useHistory();
  const {loader, alertMessage, showMessage, authUser} = useSelector(({auth}) => auth);


  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push('/');
    }
  });


  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    dispatch(showAuthLoader());
    dispatch(userSignUp(values));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
        <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">

                <img src="/assets/images/login_logo.webp" alt='Neature'/>
              </div>
              <div className="gx-app-logo-wid">
                <div className="gx-app-logo">
                  <img alt="golden-league-logo" src="/assets/images/logo_golden.png"/>
                </div>
                <h1>
                  Crea una cuenta
                </h1>
                <p>Creamos una competencia Sana y con mucha dedicación, la Golden League es una nueva experiencia en el fútbol andaluz donde participan al rededor de 1000 personas, Muchísimos Emprendedores y Empresas locales.</p>
                <p>Te invitamos a ser parte</p>
              </div>
            </div>

          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">
              <FormItem rules={[{required: true, message: 'Ingresa tu nombre completo!'}]} name="Username">
                  <Input placeholder="Nombre completo"/>
              </FormItem>

              <FormItem name="email" rules={[{
              required: true, type: 'email', message: 'Ingresa un email válido!',
              }]}>
                  <Input placeholder="Email"/>
              </FormItem>
              <FormItem name="password"
                        rules={[{required: true, message: 'Ingresa una contraseña'}]}>
                  <Input type="password" placeholder="Contraseña"/>
              </FormItem>

              <FormItem className="gx-d-flex gx-justify-content-center gx-align-items-center gx-mt-4">
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  Registrarme
                </Button>
                <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signin"> Volver al login</Link>
              </FormItem>
              <div className="gx-flex-row gx-justify-content-center gx-py-4">
                  <span className="gx-mr-2">Puedes crear una cuenta con</span>
                      <GoogleOutlined onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGoogleSignIn());
                      }}/>oogle
                </div>
                <div
                  className="gx-text-light gx-fs-sm gx-text-center">
                    Golden League
                  </div>
            </Form>
          </div>
          {loader &&
          <div className="gx-loader-view">
            <CircularProgress/>
          </div>
          }
          {showMessage &&
          message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};


export default SignUp;
