/* eslint-disable import/no-anonymous-default-export */
import {
  FETCH_MATCHES,
  FETCH_INSCRIPTIONS_SUCCESS,
  REGISTER_TO_MATCH,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  userId: localStorage.getItem('id'),
  inscriptions: [],
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_MATCHES: {
      return {
        ...state,
        loader: false,
        user: action.payload
      }
    }
    case FETCH_INSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        loader: false,
        // user: action,
        inscriptions: action.payload
      }
    }
    case REGISTER_TO_MATCH: {
      return {
        ...state,
        loader: false,
        // user: action,
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case SHOW_MESSAGE: {
      console.log('Show message: ', action.payload)
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }
    default:
      return state;
  }
}
