import React, {useEffect} from "react";
import {Button, Input, message,Form, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";

import {
  hideMessage,
  showAuthLoader,
  userGoogleSignIn,
  userSignIn,
} from "appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";
import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";

const SignIn =()=> {

  const dispatch = useDispatch();
  const {loader, alertMessage, showMessage,authUser}= useSelector(({auth}) => auth);
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
       dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push('/');
    }
  });


  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    console.log("finish",values)
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
  };

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">

                <img src="/assets/images/login_logo.webp" alt='Neature'/>
              </div>
              <div className="gx-app-logo-wid">
                <div className="gx-app-logo">
                  <img alt="golden-league-logo" src="/assets/images/logo_golden.png"/>
                </div>
                <h1>
                  Inicia Sesión
                </h1>
                <p>Creamos una competencia Sana y con mucha dedicación, la Golden League es una nueva experiencia en el fútbol andaluz donde participan al rededor de 1000 personas, Muchísimos Emprendedores y Empresas locales.</p>
                <p>Te invitamos a ser parte</p>

                <Typography.Title level={4} className="gx-text-white gx-text-center">Contáctanos por teléfono o whatsapp al +34 642740010</Typography.Title>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form
                initialValues={{ remember: true }}
                name="basic"
                onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="gx-signin-form gx-form-row0">

                <Form.Item
                  initialValue=""
                  rules={[{ required: true, message: 'Ingresa un email válido' }]} name="email">
                  <Input placeholder="Email"/>
                </Form.Item>
                <Form.Item
                  initialValue=""
                  rules= {[{required: true, message: 'Ingresa la contraseña de la cuenta'}]}  name="password">
                    <Input type="password" placeholder="Password"/>
                </Form.Item>

                <Form.Item className="gx-d-flex gx-justify-content-center gx-align-items-center gx-mt-4">
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    Inicia Sesión
                  </Button>
                  <span className="gx-mx-2"><IntlMessages id="app.userAuth.or"/></span> <Link to="/signup">
                    Regístrate</Link>
                </Form.Item>
                <div className="gx-flex-row gx-justify-content-center gx-py-4">
                  <span className="gx-mr-2">Puedes conectarte con</span>
                      <GoogleOutlined onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGoogleSignIn());
                      }}/>oogle
                </div>
                <div
                  className="gx-text-light gx-fs-sm gx-text-center">
                    Golden League
                  </div>
              </Form>
            </div>

            {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    );
  };

export default SignIn;
