import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import axios from "axios";
import { FETCH_MATCHES, REGISTER_TO_MATCH, CANCEL_REGISTER_TO_MATCH, FETCH_MY_INSCRIPTIONS_REQUEST} from '../../constants/ActionTypes';
import { fetchInscriptionsSuccess, fetchInscriptionsFailure } from '../actions'
import baseUrl from './config';
import { showAuthMessage } from '../actions/Auth'


const fetchMatches = async () => {
  const response = await axios.get(`${baseUrl}/items/match?fields[]=*.*&filter[from][_gte]=$NOW&filter[status][_eq]=PENDING&sort[]=from`)
  const {data} = response.data
  if (data)
    return data
  return []
}



function* fetchMyInscriptions() {
  try {
    console.log('fetchMyInscriptions triggered...')
    const userId = localStorage.getItem('id');
    const response = yield call(axios.get, `${baseUrl}/items/inscriptions?fields[]=*&fields[]=match.*&filter[status][_neq]=CANCELLED&filter[user][_eq]=${userId}`)

    const {data} = response.data
    yield put(fetchInscriptionsSuccess(data));
  } catch (error) {
    yield put(fetchInscriptionsFailure(error.message));
  }
}


function* registerToMatch(data) {
  try {
    const { payload } = data;
    const userId = localStorage.getItem('id');
    const body = {
      "match": payload.match.match,
      "payment_code": payload.match.paymentCode,
      "user": userId
    }
    yield call(axios.post, `${baseUrl}/items/inscriptions`, body)
    yield fetchMyInscriptions()
  } catch (error) {
    yield put(fetchInscriptionsFailure(error.message));
  }
}

function* cancelRegisterToMatch(data) {
  try {
    const { payload } = data;
    // const userId = localStorage.getItem('id');
    const body = {
      status: 'CANCELLED'
    }
    yield call(axios.patch, `${baseUrl}/items/inscriptions/${payload.inscriptionId}`, body)
    yield fetchMyInscriptions()
    yield put(showAuthMessage('Inscripción eliminada correctamente'));
  } catch (error) {
    yield put(fetchInscriptionsFailure(error.message));
  }
}


export function* getMatches() {
  yield takeEvery(FETCH_MATCHES, fetchMatches);
}


export function* watchNewRegisterToMatch() {
  yield takeLatest(REGISTER_TO_MATCH, registerToMatch);
  yield takeLatest(CANCEL_REGISTER_TO_MATCH, cancelRegisterToMatch);
  yield takeLatest(FETCH_MY_INSCRIPTIONS_REQUEST,fetchMyInscriptions);
}





export default function* rootSaga() {
  yield all([
    fork(fetchMyInscriptions),
    fork(getMatches),
    fork(watchNewRegisterToMatch)
  ]);
}
