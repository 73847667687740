import firebase from "firebase";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyC3V8Q1RqsWlqqF4XDW8nlqMJ4-XP0raIY",
  authDomain: "golden-league-dev.firebaseapp.com",
  projectId: "golden-league-dev",
  storageBucket: "golden-league-dev.appspot.com",
  messagingSenderId: "435730053273",
  appId: "1:435730053273:web:2aabc24d6a1ea7f9bd6008"
};

firebase.initializeApp(config);
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.database();

export {
  database,
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
